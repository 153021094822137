import BasePage from '../../../../../components/table_page';
import Form from './form';

export default {
  name: 'investment_type',
  extends: BasePage,
  data() {
    return {};
  },
  components: {
    Form,
  },
  created() {
    this.getConfigList('tpm_budget_fee_carry_forward');
  },
  methods: {
    // 控制按钮显示
    clickVisible({ row, val: { code } }) {
      const { status } = row;
      if (
        status !== 'N' && code === 'carry_forward'
      ) {
        return false;
      }
      return true;
    },
    modalClick({ val, row }) {
      if (val.code === 'carry_forward') {
        this.modalConfig = {
          title: '结转',
          width: '450px',
          height: '200px',
        };
        this.formConfig = {
          ...this.formConfig,
          code: val.code,
          row,
        };
        this.openModal();
      }
    },
  },
};
