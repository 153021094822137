import Form from '../../../../../../components/form';
import request from '../../../../../../utils/request';
import BudgetList from './budget_list.vue';

formCreate.component('BudgetList', BudgetList);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'carry_forward_form',
    };
  },
  methods: {

    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'intoBudgetCode') { // 选择费用预算
        v.props = {
          ...v.props,
          params: {
            functionCode: 'budget_list',
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
      }
      return v;
    },
    // 表单渲染完成后回调
    formComplete() {
      const intoBudgetCode = this.getRule('intoBudgetCode');
      // 费用预算
      intoBudgetCode.on.change = (e) => {
        if (e && e.length > 0) {
          this.setValue({
            intoBudgetCode: e[0].feeBudgetCode,
          });
        } else {
          this.setValue({
            intoBudgetCode: null,
          });
        }
      };
      request
        .get('/tpm/tpmFeeBudgetController/queryByBudgetCode', {
          budgetCode: this.formConfig.row.outBudgetCode,
        })
        .then((res) => {
          if (res.success) {
            console.log(res.result);
            this.setValue(res.result);
            intoBudgetCode.props = {
              ...intoBudgetCode.props,
              searchFormData: {
                ...intoBudgetCode.props.searchFormData,
                ext7: res.result.ext7,
                ext8: res.result.ext8,
                budgetSubjectsCode: res.result.budgetSubjectsCode,
                budgetSubjectsName: res.result.budgetSubjectsName,
                customerCode: res.result.customerCode,
                customerName: res.result.customerName,
                productLevelCode: res.result.productLevelCode,
                productLevelName: res.result.productLevelName,
                orgCode: res.result.orgCode,
                orgName: res.result.orgName,
                ext9: res.result.ext9,
                ext10: res.result.ext10,
              },
            };
          }
        });
    },
    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        const url = '/tpm/tpmcarryoverjobreport/manuCarryOver';
        const params = {
          intoBudgetCode: formData.intoBudgetCode,
          outBudgetCode: this.formConfig.row.outBudgetCode,
        };
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
